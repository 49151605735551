import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import SEO from '../components/seo';
import qPath from '../lib/qpath';

const query = graphql`
  {
    contentfulForm(contentful_id: {eq: "3cWRuWAFZsyozh9LeTuqcA"}) {
      questions {
        contentful_id
        question {
          question
        }
      }
    }
  }
`;

function IndexPage() {
  const { contentfulForm } = useStaticQuery(query);
  const meta = [
    {
      name: 'robots',
      content: 'noindex',
    },
  ];
  return (
    <>
      <SEO title="Debug" meta={meta} />
      <ol>
        {
          contentfulForm.questions.map(({ contentful_id: id, question }) => (
            <li key={id}>
              <Link to={`/debug/${qPath(id)}`}>
                {question.question}
              </Link>
            </li>
          ))
        }
      </ol>
    </>
  );
}

export default IndexPage;
